import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";

// Import styles
import "./styles/patient-information.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Icons
import {
  FaClock,
  FaCheckCircle,
  FaDownload,
  FaPlusCircle,
  FaUserCircle,
} from "react-icons/fa";

// Bootstrap imports
import { Button, Col, Container, Row } from "react-bootstrap";

const PatientInformationPage = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Patient Information" }) {
        id
        patientInformationFields {
          backgroundImage {
            altText
            localFile {
              url
            }
          }
          formsButton1 {
            text
            pdf {
              mediaItemUrl
            }
          }
          formsButton2 {
            text
            pdf {
              mediaItemUrl
            }
          }
          formsButton3 {
            text
            pdf {
              mediaItemUrl
            }
          }
          formsButton4 {
            text
            pdf {
              mediaItemUrl
            }
          }
          formsButton5 {
            text
            pdf {
              mediaItemUrl
            }
          }
          saveTime
          whatToExpect
        }
        seoFields {
          seo {
            title
            description
          }
        }
      }
    }
  `);

  // Wordpress data
  const { wpPage } = data;

  // Background image
  let backgroundImage = {
    backgroundImage: `url(${wpPage.patientInformationFields.backgroundImage.localFile.url})`,
  };

  // Form buttons
  let button1, button2, button3, button4, button5;
  if (wpPage.patientInformationFields.formsButton1.text !== "") {
    button1 = (
      <a
        target="_blank"
        href={wpPage.patientInformationFields.formsButton1.pdf.mediaItemUrl}
      >
        <Button variant="accent">
          <FaDownload />
          {wpPage.patientInformationFields.formsButton1.text}
        </Button>
      </a>
    );
  }
  if (wpPage.patientInformationFields.formsButton2.text !== "") {
    button2 = (
      <a
        target="_blank"
        href={wpPage.patientInformationFields.formsButton2.pdf.mediaItemUrl}
      >
        <Button variant="accent">
          <FaDownload />
          {wpPage.patientInformationFields.formsButton2.text}
        </Button>
      </a>
    );
  }
  if (wpPage.patientInformationFields.formsButton3.text !== null) {
    button3 = (
      <a
        target="_blank"
        href={wpPage.patientInformationFields.formsButton3.pdf.mediaItemUrl}
      >
        <Button variant="accent">
          <FaDownload />
          {wpPage.patientInformationFields.formsButton3.text}
        </Button>
      </a>
    );
  }
  if (wpPage.patientInformationFields.formsButton4.text !== null) {
    button4 = (
      <a
        target="_blank"
        href={wpPage.patientInformationFields.formsButton4.pdf.mediaItemUrl}
      >
        <Button variant="accent">
          <FaDownload />
          {wpPage.patientInformationFields.formsButton4.text}
        </Button>
      </a>
    );
  }
  if (wpPage.patientInformationFields.formsButton5.text !== null) {
    button5 = (
      <a
        target="_blank"
        href={wpPage.patientInformationFields.formsButton5.pdf.mediaItemUrl}
      >
        <Button variant="accent">
          <FaDownload />
          {wpPage.patientInformationFields.formsButton5.text}
        </Button>
      </a>
    );
  }

  return (
    <Layout>
      <MetaData
        title={wpPage.seoFields.seo.title}
        description={wpPage.seoFields.seo.description}
        image={`https://mountaineer-dental.netlify.app${image}`}
      />
      <div className="patient-information-page">
        <div
          className="patient-information-intro fade-in-slow"
          style={backgroundImage}
        />
        <div className="overlay" />
        <div className="form-container">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h2 className="shout intro-title">
              <FaPlusCircle />
              New Patients
            </h2>
            <form
              id="newPatients"
              name="Appointment Request"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input
                type="hidden"
                name="form-name"
                value="Appointment Request"
              />
              <Row>
                <Col xs={12}>
                  <h3 className="yell">Request An Appointment:</h3>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="text"
                      name="Name"
                      placeHolder="Enter your name."
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="email"
                      name="Email"
                      placeHolder="Enter your email."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <input
                      type="text"
                      name="Appointment Type"
                      placeHolder="Enter the type of appointment."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <textarea
                      name="Message"
                      placeHolder="Type us a message."
                    ></textarea>
                  </label>
                </Col>
                <Col xs={12}>
                  <button className="btn btn-accent" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
        <div className="what-to-expect">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h2 className="shout">
              <FaCheckCircle />
              What To Expect
            </h2>
            <Row className="content">
              <Col xs={12}>
                <h3 className="yell">Your First Visit:</h3>
              </Col>
              <Col className="scroll-box" xs={12}>
                <div
                  className="scroll talk"
                  dangerouslySetInnerHTML={{
                    __html: wpPage.patientInformationFields.whatToExpect,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="save-time">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h2 className="shout">
              <FaClock />
              Save Time
            </h2>
            <Row className="content">
              <Col className="scroll-box" xs={12}>
                <div
                  className="scroll talk"
                  dangerouslySetInnerHTML={{
                    __html: wpPage.patientInformationFields.saveTime,
                  }}
                />
              </Col>
              <Col xs={12}>
                {button1}
                {button2}
                {button3}
                {button4}
                {button5}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="form-container">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h2 className="shout">
              <FaUserCircle />
              Current Patients
            </h2>
            <form
              id="currentPatients"
              name="Records Release Request"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input
                type="hidden"
                name="form-name"
                value="Records Release Request"
              />
              <Row>
                <Col xs={12}>
                  <h3 className="yell">Request Records Release:</h3>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="text"
                      name="Name"
                      placeHolder="Enter your name."
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="email"
                      name="Email"
                      placeHolder="Enter your email."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <input
                      type="text"
                      name="Records Type"
                      placeHolder="Enter the type of records."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <textarea
                      name="Message"
                      placeHolder="Type us a message."
                    ></textarea>
                  </label>
                </Col>
                <Col xs={12}>
                  <button className="btn btn-accent" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default PatientInformationPage;
